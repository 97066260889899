import './App.css';
import React from 'react';
import PopupMain from './components/popup/PopupMain.jsx';
function App() {
  return (
    <div className="PopupApp">
        <PopupMain/>
    </div>
  );
}

export default App;
