import React from 'react';
import Header from './includes/Header';
import Footer from './includes/Footer';
import Content from './Content';
import '../../css/popup-style.css';


function PopupMain()
{

    return (
        <div className="popup">
            <Header/>
            <Content/>
            <Footer/>
        </div>
    );
}

export default PopupMain;