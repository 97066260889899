import React, { useState, useEffect } from 'react';
import http from '../../services/httpService';
import config from '../../../config.json';

const RightAds = () => {
    const [desktopRightAd, setDesktopRightAd] = useState([]);

    const getDesktopRightAd = async () => {
        const { data } = await http.get(config.baseUrl + 'get-site-images/3');
        setDesktopRightAd(data.siteImages);
    };

    useEffect(() => {
        getDesktopRightAd();
     }, []);
    
    return ( 
        <>
           { Object.keys(desktopRightAd).length > 0 && (
                <a href={desktopRightAd.link ? desktopRightAd.link : "#"} target="_blank">
                    <img className='w-100' src={desktopRightAd.image} alt="Right Ad" />
                </a>
                
            )}
        </>
     );
}
 
export default RightAds;