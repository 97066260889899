import React, { useState, useEffect } from 'react';
import http from '../../services/httpService';
import config from '../../../config.json';

const MobileFooterAd = () => {
    const [desktopLeftAd, setDesktopLeftAd] = useState([]);

    const getDesktopLeftAd = async () => {
        const { data } =  await http.get(config.baseUrl + 'get-site-images/2');
        setDesktopLeftAd(data.siteImages);
    };

    useEffect(() => {
        getDesktopLeftAd();
     }, []);
    
    return ( 
        <>
            { Object.keys(desktopLeftAd).length > 0 && (
                <a href={desktopLeftAd.link ? desktopLeftAd.link : "#"} target="_blank">
                    <img className='w-100' src={desktopLeftAd.image} alt="left Ad" />
                </a>
                
            )}
        </>
     );
}
 
export default MobileFooterAd;