import React, { useState, useEffect } from 'react';
import http from '../../services/httpService';
import config from '../../../config.json';

const CenterAds = () => {
    const [desktopCenterAd, setDesktopCenterAd] = useState([]);

    const getDesktopCenterAd = async () => {
        const { data } = await http.get(config.baseUrl + 'get-site-images/6');
        setDesktopCenterAd(data.siteImages);
    };

    useEffect(() => {
        getDesktopCenterAd();
     }, []);
    
    return ( 
        <>
            { Object.keys(desktopCenterAd).length > 0 && (
                <a href={desktopCenterAd.link ? desktopCenterAd.link : "#"} target="_blank">
                    <img className='w-100' src={desktopCenterAd.image} alt="Center Ad" />
                </a>
            )}
        </>
     );
}
 
export default CenterAds;