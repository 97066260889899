import React, { useState, useEffect } from 'react';
import http from '../services/httpService';
import config from '../../config.json';

function Content() {

    const [extensionLogo, setExtensionLogo] = useState('');
    const [extensionPages, setExtensionPages] = useState([]); 

    const getExtensionSettings = async () => {
        const { data } = await http.get(config.baseUrl + 'get-extension-settings');
        setExtensionPages(data.data);
        setExtensionLogo(data.logo);
    };
    
    useEffect(() => {
        getExtensionSettings();
    }, []);

    return (
        <div>
            <div className="container-fluid">
                <div className="row border header">
                    <div className="col-12 d-flex justify-content-center">
                        <a href={config.publicSiteUrl} target="_blank">
                            <img src={extensionLogo} alt="Logo" />
                        </a>
                    </div>
                </div>
            </div>
            <div className="container-fluid content p-0">
                <div className="tab-contents">
                    { 
                        Object.keys(extensionPages).map(keyOuter => 
                        {
                            let pageData = extensionPages[keyOuter];
                            return (
                                <a href={pageData.page_url} target="_blank" className="page-content"> 
                                    <div className="w-100 border">
                                        <img src={pageData.icon_url} alt={pageData.page_name}/>
                                        <h4>{pageData.page_name}</h4>
                                    </div>
                                </a>
                            )
                        })
                    }
                </div>
            </div>
        </div> 
    );    
}

export default Content;