import React, { useState, useEffect } from 'react';
import http from '../../services/httpService';
import config from '../../../config.json';
import Moment from 'react-moment';
import moment from 'moment-timezone';
import LeftAds from './LeftAds'; //Desktop Left Ads
import RightAds from './RightAds'; //Desktop Left Ads
import CenterAds from './CenterAds'; //Desktop Left Ads


function Footer(){

    const defaultTimeZone = 'America/Chicago';
    const tz = moment.tz.guess();
    const [currentDate, setCurrentTime] = useState(moment());
    const todayDate =  moment.tz(currentDate, defaultTimeZone);  
    const [siteSetting, setSiteSetting] = useState([]); // site settings
    const [menus, setMenus] = useState([]);
    const [adminUrl, setAdminUrl] = useState([]);
    const [publicUrl, setPublicUrl] = useState(config.publicSiteUrl);
    const [copyRightTextMobile, setCopyRightTextMobile] = useState('text-left'); //mobile copyright text position

    const getMenus = async () => {
        const { data } = await http.get(config.baseUrl + 'menus');
        const menus = data.data.menus;
        const adminUrl = data.data.url;
        setMenus(menus);
        setAdminUrl(adminUrl);
    };

    const getSiteSetting = async () => {
        const { data } = await http.get(config.baseUrl + 'public-settings');
        const siteSetting = data.data.siteSetting;
       
        setSiteSetting(siteSetting);
        let alignmentMobile = 'copyright-left'; 
        if(siteSetting.mobile_copyright_alignment === 1)
        {
            alignmentMobile = 'copyright-center';
        }
        else if(siteSetting.mobile_copyright_alignment === 2)
        {
            alignmentMobile = 'copyright-right';
        }

        setCopyRightTextMobile('mb-0 ' + alignmentMobile);
    };

    useEffect(() => {
       getMenus();
       getSiteSetting();
        const interval = setInterval(() => {
        setCurrentTime(moment());
        }, 1000);
        return () => clearInterval(interval);
    }, []);

    return (
       <div className="container-fluid popupfooter">
            <div className="row">
               
                <div className="col-4 py-1 border">
                    { siteSetting.mobile_left_ad_banner_image_toggle === '1' && (
                        <LeftAds/>
                    )}
                </div>
                <div className="col-4 py-1 border">
                    { siteSetting.mobile_left_ad_banner_image_toggle === '1' && (
                        <CenterAds/>
                    )}
                </div>
                <div className="col-4 py-1 border">
                    { siteSetting.mobile_left_ad_banner_image_toggle === '1' && (
                        <RightAds/>
                    )}
                </div>
            </div>
            <div className="row footer-bg">
                {
                    menus.map((menu) => (
                        <div className="col-2 text-center">
                            <a href={publicUrl  + menu.name.toLowerCase()} target="_blank">
                                <img src={adminUrl + '/icons/' + menu.icon} alt={menu.name} />
                                <h4 className="text-center">{menu.name}</h4>
                            </a>
                        </div>
                    ))
                }
            </div>
       </div>
    );
}

export default Footer;